import React from "react";
import { Redirect } from "react-router-dom";
import firebase from "../firebase";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navigation = props => {
  const { t, i18n } = useTranslation();

  return (
    <Navbar style={{backgroundColor: "#26559c"}} variant="dark" expand="md">
      <Navbar.Brand href="/">
        {" "}
        <img
          src="/logo.png"
          width="30"
          height="30"
          style={{ borderRadius: 20, backgroundColor: "#fff" }}
          className="d-inline-block align-top"
          alt="Brecon Beacons National Park"
        />{" "}
        {t("navbar-title")}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="mr-auto">
          <LinkContainer to="/">
            <Nav.Link>
              <FontAwesomeIcon icon="home" /> {t("nav-documents")}
            </Nav.Link>
          </LinkContainer>
          {firebase.auth().currentUser ? (
            <LinkContainer to="/my-comments">
              <Nav.Link>
                <FontAwesomeIcon icon="comment" /> {t("nav-my-comments")}
              </Nav.Link>
            </LinkContainer>
          ) : (
            ""
          )}
        </Nav>
        <Nav>
          <Nav.Link href={"/?lng=" + (i18n.language === "en" ? "cy" : "en")}>
            {t("language-selector")}
          </Nav.Link>
          <UserNavLink profile={props.profile} />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;

class UserAuthState extends React.Component {
  constructor(props) {
    super(props);
    this.state = { shouldRedirectHome: false };
  }

  handleSignOut = props => {
    firebase.auth().signOut();
    this.setState({ shouldRedirectHome: true });
  };

  componentDidUpdate() {
    if (this.shouldComponentUpdate) {
      this.setState({ shouldRedirectHome: false });
    }
  }

  render() {
    if (firebase.auth().currentUser !== null) {
      return (
        <React.Fragment>
          <Nav.Link onClick={this.handleSignOut}>
            {this.props.t("nav-signout")}
          </Nav.Link>
          <Navbar.Text>({firebase.auth().currentUser.displayName})</Navbar.Text>
          {this.state.shouldRedirectHome ? (
            <Redirect to={{ pathname: "/" }} />
          ) : (
            ""
          )}
        </React.Fragment>
      );
    } else {
      return (
        <LinkContainer to="/sign-in">
          <Nav.Link>{this.props.t("nav-register-signin")}</Nav.Link>
        </LinkContainer>
      );
    }
  }
}

let UserNavLink = withTranslation()(UserAuthState);
