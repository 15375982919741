import React from "react";
import { Container, Button, Modal } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";
import L from "leaflet";
import "proj4leaflet";
import { EditControl } from "react-leaflet-draw";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Control from "react-leaflet-control";

import {
  Map,
  WMSTileLayer,
  LayersControl,
  FeatureGroup
} from "react-leaflet";

const { BaseLayer } = LayersControl;

var EPSG27700 =
  "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs";

var crs = new L.Proj.CRS("EPSG:27700", EPSG27700, {
  //scales: [1600,800,400,200,100,50,25,10,5,2.5,1,0.5,0.25,0.125,0.0625]
  scales: [0.018, 0.03125, 0.0625, 0.125, 0.25, 0.5, 1, 2.5, 5]
  //resolutions: [250, 80, 35, 12.35, 8, 4, 2, 1]
});

const DEFAULT_VIEWPORT = {
  center: [51.87, -3.45],
  zoom: 0
};

class SubmitCandidateSite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: DEFAULT_VIEWPORT,
      showIntro: true
    };

    this.featureGroup = React.createRef();
  }

  hideIntro = () => {
    this.setState({ showIntro: false });
  };

  toggleCommentOverlay = () => {
    this.setState({ showCommentOverlay: !this.showCommentOverlay });
  };

  continueToSubmitComment = () => {
    if (this.featureGroup.current.leafletElement.toGeoJSON().features.length > 0) {
        this.props.history.push("/submit-comment?document=LDP2_CS_1",{
            geoJSON: this.featureGroup.current.leafletElement.toGeoJSON()
          });
    }else{
        alert(this.props.t('candidate-site-submit-no-site-submitted'));
    }
    

  };

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        {this.state.showIntro ? (
          <IntroductionModal close={this.hideIntro} />
        ) : (
          ""
        )}

        <Map
          style={{ width: "auto", height: "calc(100% - 70px)" }}
          crs={crs}
          viewport={this.state.viewport}
          onClick={this.handleClickMap}
          maxZoom={10}
        >
          <LayersControl position="topright">
            <BaseLayer checked={true} name={this.props.t("os")}>
              <WMSTileLayer
                url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/ospmsawales.wmsx"
                layers={"ospmsacomposite"}
                format={"image/png"}
                attribution={this.props.t("os-copyright")}
              />
            </BaseLayer>
            <BaseLayer checked={false} name={this.props.t("os-greyscale")}>
              <WMSTileLayer
                url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/ospmsawales.wmsx"
                layers={"ospmsacomposite"}
                format={"image/png"}
                styles={"Grey"}
                attribution={this.props.t("os-copyright")}
              />
            </BaseLayer>

            <BaseLayer checked={false} name={this.props.t("os-faded")}>
              <WMSTileLayer
                url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/ospmsawales.wmsx"
                layers={"ospmsacomposite"}
                format={"image/png"}
                styles={"Faded"}
                attribution={this.props.t("os-copyright")}
              />
            </BaseLayer>

            <BaseLayer checked={false} name={this.props.t("os-open-data")}>
              <WMSTileLayer
                url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/osopenwales.wmsx"
                layers={"OSVectorMapDistrict"}
                format={"image/png"}
              />
            </BaseLayer>

            <BaseLayer
              checked={false}
              name={this.props.t("explorer-layer-aerial")}
            >
              <WMSTileLayer
                url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/wales.wmsx"
                layers={"2017_UK_125mm"}
                format={"image/jpeg"}
              />
            </BaseLayer>

            <FeatureGroup ref={this.featureGroup}>
              <EditControl
                position="topright"
                onCreated={this.onCreate}
                draw={{
                  rectangle: false,
                  circle: false,
                  polyline: false,
                  marker: false,
                  circlemarker: false
                }}
              />
            </FeatureGroup>
          </LayersControl>
          <Control position="bottomright">
            <Button color="primary" onClick={this.continueToSubmitComment}>
              <FontAwesomeIcon icon="arrow-circle-right" />{" "}
              {this.props.t("continue-add-comment")}
            </Button>
          </Control>
        </Map>
        <Container />
      </React.Fragment>
    );
  }
}

export default withTranslation()(SubmitCandidateSite);

const IntroductionModal = props => {
  const { t } = useTranslation();

  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>
          <FontAwesomeIcon icon="draw-polygon" />{" "}
          {t("candidate-site-submit-intro-title")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{t("candidate-site-submit-intro-para-one")}</p>
        <p>{t("candidate-site-submit-intro-para-two")}</p>
        <p>{t("candidate-site-submit-intro-para-three")}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.close} variant="primary">
          {t("get-started")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
