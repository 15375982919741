import firebase from "../firebase";
import React from "react";
import { Container } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import LoadingWidget from "./LoadingWidget";

class SignInRedirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.signInWithEmailLink();
  }

  signInWithEmailLink() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(result => {
          window.localStorage.removeItem("emailForSignIn");
          this.props.history.push("/");
        })
        .catch(error => {
          console.log(error);
          this.setState({
            error: true,
            message: this.props.t("sign-in-error-message")
          });
        });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Container>
          <h1>{this.props.t("sign-in-error-title")}</h1>
          <p>{this.props.t("sign-in-error-desc")}</p>
          <p>{this.state.error.message}</p>
          <a href="/sign-in">{this.props.t("sign-in-error-link")}</a>
        </Container>
      );
    } else {
      return <LoadingWidget message={this.props.t("Signing you in...")} />;
    }
  }
}

export default withTranslation()(SignInRedirect);
