import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import './custom.scss';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelopeOpen,
  faCheckCircle,
  faComment,
  faDrawPolygon,
  faArrowCircleRight,
  faRandom,
  faCloudUploadAlt,
  faMap,
  faHome,
  faMapMarked,
  faFileAlt,
  faFolder,
  faMapMarker,
  faCopy
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faEnvelopeOpen,
  faCheckCircle,
  faHome,
  faMapMarked,
  faCloudUploadAlt,
  faComment,
  faMap,
  faRandom,
  faDrawPolygon,
  faArrowCircleRight,
  faFileAlt,
  faFolder,
  faMapMarker,
  faCopy
);



ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
