import i18n from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./translations/locales/en/translation.json";
import cy from "./translations/locales/cy/translation.json";

// the translations
const resources = {
  en: {
    translation: en
  },
  cy: {
    translation: cy
  }
};

i18n
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    react: {
      wait: false,
      nsMode: "fallback"
    }
  });

  export default i18n;