import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import firebase from "../firebase";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      uid: this.props.uid
    };
  }

  componentWillMount() {
    this.update();
  }

  update() {
    if (this.props.uid && this.props.uid !== "") {
      const db = firebase.firestore();
      db.collection("users")
        .doc(this.props.uid)
        .get()
        .then(user => {
          this.setState({ ...user.data(), loaded: true });
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uid !== this.props.uid) {
      this.update();
    }
  }

  render = () => {
    if (this.state.loaded) {
      return (
        <Container className="mt-2">
          <Form>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>
                  Title{" "}
                  <CopyToClipboard text={this.state.title}>
                    <FontAwesomeIcon icon="copy" />
                  </CopyToClipboard>
                </Form.Label>
                <Form.Control readOnly type="text" value={this.state.title} />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>
                  First name{" "}
                  <CopyToClipboard text={this.state.firstname}>
                    <FontAwesomeIcon icon="copy" />
                  </CopyToClipboard>
                </Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  value={this.state.firstname}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>
                  Last name{" "}
                  <CopyToClipboard text={this.state.lastname}>
                    <FontAwesomeIcon icon="copy" />
                  </CopyToClipboard>
                </Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  value={this.state.lastname}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>
                  Telephone{" "}
                  <CopyToClipboard text={this.state.phone}>
                    <FontAwesomeIcon icon="copy" />
                  </CopyToClipboard>
                </Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  value={this.state.phone}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>
                  Email{" "}
                  <CopyToClipboard text={this.state.email}>
                    <FontAwesomeIcon icon="copy" />
                  </CopyToClipboard>
                </Form.Label>
                <Form.Control readOnly type="text" value={this.state.email} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>
                  Address{" "}
                  <CopyToClipboard text={this.state.address}>
                    <FontAwesomeIcon icon="copy" />
                  </CopyToClipboard>
                </Form.Label>
                <Form.Control readOnly type="text" value={this.state.address} />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>
                  Postcode{" "}
                  <CopyToClipboard text={this.state.postcode}>
                    <FontAwesomeIcon icon="copy" />
                  </CopyToClipboard>
                </Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  value={this.state.postcode}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Preferred Contact Method </Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  value={this.state.preferredContactMethod}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Container>
      );
    } else {
      return (
        <Container>
          <Row>Loading User</Row>
        </Container>
      );
    }
  };
}

export default User;
