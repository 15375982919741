import React from "react";
import {
  Container,
  Button,
  Modal,
  Col,
  Row,
  //ButtonGroup,
  //FormControl,
  Badge
} from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";
import L from "leaflet";
import "proj4leaflet";
import firebase from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkContainer } from "react-router-bootstrap";

import { Map, WMSTileLayer, LayersControl, GeoJSON } from "react-leaflet";

const { BaseLayer, Overlay } = LayersControl;

var EPSG27700 =
  "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs";

var crs = new L.Proj.CRS("EPSG:27700", EPSG27700, {
  //scales: [1600,800,400,200,100,50,25,10,5,2.5,1,0.5,0.25,0.125,0.0625]
  scales: [0.018, 0.03125, 0.0625, 0.125, 0.25, 0.5, 1, 2.5, 5]
  //resolutions: [250, 80, 35, 12.35, 8, 4, 2, 1]
});

const DEFAULT_VIEWPORT = {
  center: [51.87, -3.45],
  zoom: 0
};

class ViewCandidateSites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: DEFAULT_VIEWPORT,
      showIntro: true,
      selectedSite: null,
      candidateSites: null,
      sites: [
        { title: "Site 1", contributing: "PS Contributing" },
        { title: "Site 2", contributing: "Not Contributing" },
        { title: "Site 3", contributing: "Not Contributing" },
        { title: "Site 4", contributing: "5 Year Supply" },
        { title: "Site 4", contributing: "Not Contributing" },
        { title: "Site 5", contributing: "5 Year Supply" },
        { title: "Site 6", contributing: "Not Contributing" },
        { title: "Site 7", contributing: "PS Contributing" },
        { title: "Site 8", contributing: "Not Contributing" },
        { title: "Site 9", contributing: "Not Contributing" }
      ]
    };
  }

  hideIntro = () => {
    this.setState({ showIntro: false });
  };

  toggleCommentOverlay = () => {
    this.setState({ showCommentOverlay: !this.showCommentOverlay });
  };

  continueToSubmitComment = () => {
    if (
      this.featureGroup.current.leafletElement.toGeoJSON().features.length > 0
    ) {
      this.props.history.push("/submit-comment?document=CS", {
        geoJSON: this.featureGroup.current.leafletElement.toGeoJSON()
      });
    } else {
      alert(this.props.t("candidate-site-submit-no-site-submitted"));
    }
  };

  componentDidMount() {
    let xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      "https://gis.beacons-npa.gov.uk/geoserver/bbnpa_external/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=bbnpa_external:Candidate%20Sites%20Centroids&outputFormat=json&srsName=EPSG:4326"
    );
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.responseType = "json";
    xhr.onload = () => {
      if (xhr.status !== 200) return;
      this.setState({ candidateSites: xhr.response });
    };
    xhr.send();
  }

  onEachSite = (feature, layer) => {
    layer.on("click", () => {
      this.siteInfo(feature);
    });
  };

  styleEachSite = site => {};

  siteInfo = feature => {
    this.setState({ site: feature });
  };

  closeSiteInfo = () => {
    this.setState({ site: null });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showIntro ? (
          <IntroductionModal close={this.hideIntro} />
        ) : (
          ""
        )}
        {this.state.site ? (
          <SiteInfo close={this.closeSiteInfo} {...this.state.site} />
        ) : (
          ""
        )}
        <Container fluid className="px-0" style={{ height: "100%" }}>
          {this.state.selectedSite ? (
            <CandidateSite {...this.state.selectedSite} />
          ) : (
            ""
          )}{/*
          <Row className="my-2">
            <Col className="ml-3">
              <ButtonGroup aria-label="Basic example">
                <Button variant="primary">All</Button>
                <Button variant="secondary">Employment</Button>
                <Button variant="secondary">Housing</Button>
                <Button variant="secondary">Mixed</Button>
              </ButtonGroup>
            </Col>
            <Col className="col-2 mr-3">
              <FormControl
                type="text"
                placeholder="Search site ref"
                className="mr-sm-2"
              />
            </Col>
          </Row>*/}
          <Row style={{ height: "100%" }}>
            <Col style={{ height: "100%" }}>
              <Map
                style={{ width: "auto", height: "100%" }}
                crs={crs}
                viewport={this.state.viewport}
                onClick={this.handleClickMap}
                maxZoom={10}
              >
                <LayersControl position="topright">
                  <BaseLayer checked={false} name={this.props.t("os")}>
                    <WMSTileLayer
                      url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/ospmsawales.wmsx"
                      layers={"ospmsacomposite"}
                      format={"image/png"}
                      attribution={this.props.t("os-copyright")}
                    />
                  </BaseLayer>
                  <BaseLayer
                    checked={false}
                    name={this.props.t("os-greyscale")}
                  >
                    <WMSTileLayer
                      url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/ospmsawales.wmsx"
                      layers={"ospmsacomposite"}
                      format={"image/png"}
                      styles={"Grey"}
                      attribution={this.props.t("os-copyright")}
                    />
                  </BaseLayer>

                  <BaseLayer checked={true} name={this.props.t("os-faded")}>
                    <WMSTileLayer
                      url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/ospmsawales.wmsx"
                      layers={"ospmsacomposite"}
                      format={"image/png"}
                      styles={"Faded"}
                      attribution={this.props.t("os-copyright")}
                    />
                  </BaseLayer>

                  <BaseLayer
                    checked={false}
                    name={this.props.t("os-open-data")}
                  >
                    <WMSTileLayer
                      url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/osopenwales.wmsx"
                      layers={"OSVectorMapDistrict"}
                      format={"image/png"}
                    />
                  </BaseLayer>

                  <BaseLayer
                    checked={false}
                    name={this.props.t("explorer-layer-aerial")}
                  >
                    <WMSTileLayer
                      url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/wales.wmsx"
                      layers={"2017_UK_125mm"}
                      format={"image/jpeg"}
                    />
                  </BaseLayer>
                  <Overlay
                    checked={true}
                    name={this.props.t("view-candidate-sites-candidate-sites")}
                  >
                    <WMSTileLayer
                      url="https://gis.beacons-npa.gov.uk/geoserver/bbnpa_external/wms"
                      layers={"bbnpa_external:candidate_sites"}
                      transparent={"true"}
                      format={"image/png"}
                    />
                  </Overlay>
                </LayersControl>
                {this.state.candidateSites ? (
                  <GeoJSON
                    key="my-geojson"
                    style={this.styleEachSite}
                    data={this.state.candidateSites}
                    onEachFeature={this.onEachSite}
                  />
                ) : (
                  ""
                )}
              </Map>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ViewCandidateSites);

const IntroductionModal = props => {
  const { t } = useTranslation();
  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>
          <FontAwesomeIcon icon="map-marked" />{" "}
          {t("candidate-sites-intro-title")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{t("candidate-sites-intro-para-one")}</p>
        <p>{t("candidate-sites-intro-para-two")}</p>
        <p>{t("candidate-sites-intro-para-three")}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.close} variant="primary">
          {t("get-started")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const SiteInfo = props => {
  const { t } = useTranslation();
  console.log(props);

  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>
          <FontAwesomeIcon icon="map-marker" /> Site Reference {props.properties.siteref}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col><em>{props.properties.site_description}</em></Col>
        </Row>
        <Row>
          <Col>{t("view-candidate-status")}</Col>
          <Col>
            {props.properties.status === "Passed" ? (
              <Badge variant="success">{props.properties.status}</Badge>
            ) : (
              <Badge variant="danger">{props.properties.status}</Badge>
            )}
          </Col>
        </Row>
        <Row>
          <Col>{t("view-candidate-site-use")}</Col>
          <Col>{t(props.properties.ldsiteuse)}</Col>
        </Row>
        <Row>
          <Col>{t("view-candidate-area")}</Col>
          <Col>{props.properties.area}</Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        {firebase.auth().currentUser ? (
           <LinkContainer to={"/submit-comment?siteref="+ props.properties.siteref +"&policy=LDP2_CS_1"}>
          <Button variant="primary">
            {t("candidate-sites-comment-on-site")}
          </Button>
          </LinkContainer>
        ) : (
          <LinkContainer to="/sign-in">
            <Button variant="warning">
              {t("view-candidate-must-sign-in")}
            </Button>
          </LinkContainer>
        )}
        <Button onClick={props.close} variant="outline-secondary">
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

class CandidateSite extends React.Component {
  render() {
    return (
      <Modal show={true}>
        <Modal.Header>Our site reference {this.props.site}</Modal.Header>
        <Modal.Body>
          Land Use <em>{this.props.landuse}</em>
        </Modal.Body>
        {/*<Modal.Footer><LinkContainer><Button>{}</Button></LinkContainer></Modal.Footer>*/}
      </Modal>
    );
  }
}
