import React from "react";
import {
  Container,
  Button,
  Alert,
  Row,
  Col,
  Modal,
  Jumbotron
} from "react-bootstrap";
import firebase from "../firebase";
import { withTranslation, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

const DOCUMENT_LIST_ENDPOINT = "https://api.beacons-npa.gov.uk/ldp/list";
const IGNORE_REGISTER_ALERT = "ignore-register-alert";

class LDPDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChooseCandidateSiteSubmissionTypeModal: false,
      documents: {}
    };
  }

  toggleChooseCandidateSiteSubmissionTypeModal = () => {
    this.setState({
      showChooseCandidateSiteSubmissionTypeModal: !this.state
        .showChooseCandidateSiteSubmissionTypeModal
    });
  };

  componentDidMount() {
    fetch(DOCUMENT_LIST_ENDPOINT)
      .then(response => response.json())
      .then(data => this.setState({ documents: data }));
  }

  render() {

    //Move Preferred Strategy to the top
    let docKeys = Object.keys(this.state.documents);
    docKeys.sort(function(x,y){ return x === "LDP2_PS" ? -1 : y === "LDP2_PS"
     ? 1 : 0; });


    return (
      <Container className="mt-3">
        {this.state.showChooseCandidateSiteSubmissionTypeModal ? (
          <ChooseCandidateSiteSubmissionType
            close={this.toggleChooseCandidateSiteSubmissionTypeModal}
          />
        ) : (
          ""
        )}
        {firebase.auth().currentUser ? "" : <TranslatableSignInPrompt />}
        <h1>{this.props.t("ldp-documents-title")}</h1>
        <hr />
        {docKeys.map((doc, idx) => {
          return (
              <Row className="ml-1" key={idx}>
                <Col>
                  <h4>
                    <FontAwesomeIcon icon="folder" color="#ccc" />{" "}
                    <Link to={"/documents/" + doc}>
                      {this.props.t(doc)}
                    </Link>
                  </h4>
                </Col>
              </Row>
          );
        })}

        <Jumbotron
          style={{ backgroundImage: "url('cs-bg.png')" }}
          className="my-5"
        >
          <div
            style={{ backgroundColor: "rgba(255, 255, 255, .8)" }}
            className="p-5 d-inline-block"
          >
            <h1>{this.props.t("ldp-documents-candidate-heading")}</h1>
            <p>{this.props.t("ldp-documents-candidate-intro")}</p>
            <p>
              <Link to="/view-candidate-sites">
                <Button variant="primary">
                  {firebase.auth().currentUser ? this.props.t("ldp-documents-comments-view-and-comment") : this.props.t("ldp-documents-comments-view") }
                </Button>
              </Link>{" "}
              <SubmitCandidateSiteLink
                click={this.toggleChooseCandidateSiteSubmissionTypeModal}
              />
            </p>
          </div>
        </Jumbotron>
      </Container>
    );
  }
}
export default withTranslation()(LDPDocuments);

const SubmitCandidateSiteLink = props => {
  const { t } = useTranslation();

  if (!firebase.auth().currentUser) {
    return (
      <LinkContainer to="/sign-in">
        <Button variant="warning">
          {t("ldp-documents-comments-must-sign-in")}
        </Button>
      </LinkContainer>
    );
  }
  return (
    <Button onClick={props.click}>{t("ldp-documents-suggest-site")}</Button>
  );
};

class SignInPrompt extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: !(localStorage.getItem(IGNORE_REGISTER_ALERT) || false)
    };
  }

  handleDismiss = () => {
    localStorage.setItem(IGNORE_REGISTER_ALERT, true);
    this.setState({ show: false });
  };

  render() {
    const handleHide = () => this.setState({ show: false });
    return (
      <>
        <Alert
          show={this.state.show}
          variant="success"
          onClose={this.handleDismiss}
          dismissible
        >
          <Alert.Heading>{this.props.t("prompt-signin-title")}</Alert.Heading>
          <p>{this.props.t("prompt-signin-text")}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <LinkContainer to="/sign-in">
              <Button onClick={handleHide} variant="outline-success">
                {this.props.t("prompt-signin-button")}
              </Button>
            </LinkContainer>
          </div>
        </Alert>
      </>
    );
  }
}

const TranslatableSignInPrompt = withTranslation()(SignInPrompt);

const ChooseCandidateSiteSubmissionType = props => {
  const { t } = useTranslation();

  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>
          <FontAwesomeIcon icon="random" />{" "}
          {t("ldp-documents-choose-candidate-submission-type")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-center">
        <p>{t("ldp-documents-choose-candidate-submission-type-details")}</p>
        <LinkContainer to="/submit-candidate-site">
          <Button onClick={props.close} variant="primary">
            <FontAwesomeIcon icon="draw-polygon" />{" "}
            {t("ldp-documents-choose-candidate-submission-type-online-map")}
          </Button>
        </LinkContainer>
        <p className="mt-3">{t("ldp-documents-or")}</p>
        <LinkContainer to={"/submit-comment?policy=LDP2_CS_1"}>
          <Button onClick={props.close} variant="primary">
            <FontAwesomeIcon icon="cloud-upload-alt" />{" "}
            {t("ldp-documents-choose-candidate-submission-type-shapefile")}
          </Button>
        </LinkContainer>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.close} variant="outline-secondary">
          {t(
            "ldp-documents-choose-candidate-submission-type-cancel-submission"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
