import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyDpYlPRoRNEQLJKmoqWQfBP3-BccujfmZM",
    authDomain: "bbnpa-ldp.firebaseapp.com",
    databaseURL: "https://bbnpa-ldp.firebaseio.com",
    projectId: "bbnpa-ldp",
    storageBucket: "bbnpa-ldp.appspot.com",
    messagingSenderId: "594930470401",
    appId: "1:594930470401:web:83712ffa46d664a0"
  };

firebase.initializeApp(firebaseConfig);

export default firebase;