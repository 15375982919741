import React from "react";
import { Container, Col, Row, Spinner } from "react-bootstrap";

const LoadingWidget = props => {

  return (
    <Container>
      <Row className="text-center mt-5">
        <Col>
          <Spinner
            animation="border"
            className="mb-2"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
            variant="primary"
          >
            <span className="sr-only">{props.message}</span>
          </Spinner>
          <h3 className="justify-content-center">{props.message}</h3>
        </Col>
      </Row>
    </Container>
  );
};

export default LoadingWidget;
