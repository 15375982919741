import React from "react";
import { Container, Form, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { string, bool, object, number } from "yup";
import i18n from "i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import firebase from "../firebase";

const documentFields = {
  LDP2_PS: {
    type: "LDP2_PS",
    title: "Preferred Strategy",
    schema: object({
      response: string().oneOf(
        ["supporting", "objecting", "commenting"],
        i18n.t("submit-comment-must-give-response")
      ),
      comment: string().required(),
      paragraph: number(),
      chapter: number(),
      diagram: number(),
      policy: string(),
      wholeDocument: bool(),
      acceptTerms: bool().oneOf(
        [true],
        i18n.t("submit-comment-must-accept-terms")
      )
    }),
    initialValues: {
      response: "Choose one...",
      paragraph: "",
      chapter: "",
      diagram: "",
      policy: "",
      wholeDocument: false,
      acceptTerms: false,
      comment: ""
    }
  },
  LDP2_SA: {
    type: "LDP2_SA",
    title: "Sustainability Appraisal (SA)",
    schema: object({
      paragraph: number(),
      chapter: number(),
      diagram: number(),
      wholeDocument: bool(),
      acceptTerms: bool().oneOf(
        [true],
        i18n.t("submit-comment-must-accept-terms")
      ),
      comment: string().required()
    }),
    initialValues: {
      paragraph: "",
      chapter: "",
      diagramme: "",
      wholeDocument: false,
      acceptTerms: true,
      comment: ""
    }
  },
  LDP2_HRA: {
    type: "LDP2_HRA",
    title: "Habitat Regulations Assessment (HRA)",
    schema: object({
      comment: string().required(),
      paragraph: number(),
      chapter: number(),
      diagram: number(),
      acceptTerms: bool().required(),
      policy: string(),
      wholeDocument: bool
    }),
    initialValues: {
      paragraph: "",
      chapter: "",
      diagram: "",
      wholeDocument: false,
      acceptTerms: false,
      comment: ""
    }
  },
  LDP2_CS_1: {
    type: "LDP2_CS_1",
    title: "Candidate Site",
    schema: object({
      acceptTerms: bool().required(),
      comment: string().required()
    }),
    initialValues: {
      comment: "",
      acceptTerms: false
    }
  },
  LDP2_EVI: {
    type: "LDP2_EVI",
    title: "Evidence",
    schema: object({
      documentTitle: string().required(),
      acceptTerms: bool().required(),
      comment: string().required()
    }),
    initialValues: {
      documentTitle: "",
      comment: "",
      acceptTerms: false
    }
  }
};

class ViewComment extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.comment);

    this.state = {
      document: documentFields[this.props.comment.type],
      files: []
    };
    this.loadFileURLs();
  }

  downloadAttachment = filename => {
    firebase
      .storage()
      .ref("attachments")
      .child(filename)
      .getDownloadURL()
      .then(url => (window.location = url));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.comment.cid !== this.props.comment.cid) {
      this.setState({ files: [] }, () => {
        console.log("load filea");
        this.loadFileURLs();
      });
    }
  }

  loadFileURLs = () => {
    this.props.comment.attachments.map((attachment, idx) => {
      firebase
        .storage()
        .ref("attachments")
        .child(attachment)
        .getDownloadURL()
        .then(url => {
          console.log(this.state.files)
          this.setState({
            files: [...this.state.files, url]
          });
        });
        return "";
    });
  };

  render() {
    const t = this.props.t;
    return (
      <Container className="pb-5 mt-4">
        <h2>
          {this.state.document.title}({this.props.comment.type}{" "}
          <CopyToClipboard text={this.state.type}>
            <small>
              <FontAwesomeIcon icon="copy" />
            </small>
          </CopyToClipboard>
          )
        </h2>
        <Form className="mt-3">
          {this.state.document.initialValues.hasOwnProperty("response") ? (
            <Form.Row>
              <Form.Group as={Col} md={4} controlId="formGridResponse">
                <Form.Label>{t("submit-comment-response-field")}</Form.Label>
                <Form.Control
                  as="text"
                  name="response"
                  readonly
                  defaultValue={this.state.response}
                ></Form.Control>
              </Form.Group>
            </Form.Row>
          ) : (
            ""
          )}

          <Form.Group controlId="formGridComment">
            <Form.Label>
              Comment{" "}
              <CopyToClipboard text={this.props.comment.comment}>
                <FontAwesomeIcon icon="copy" />
              </CopyToClipboard>
            </Form.Label>
            <Form.Control
              name="comment"
              value={this.props.comment.comment}
              as="textarea"
              rows="8"
              readOnly
            />
          </Form.Group>

          {this.state.document.initialValues.hasOwnProperty("wholeDocument") ? (
            <Form.Group id="formGridCheckbox">
              <Form.Check
                type="checkbox"
                label={t("submit-comment-whole-document")}
                name="wholeDocument"
                defaultValue={this.props.comment.wholeDocument}
                id="wholeDocument"
                readonly
              />
            </Form.Group>
          ) : (
            ""
          )}
          <Form.Row>
            {this.state.document.initialValues.hasOwnProperty("paragraph") ? (
              <Form.Group as={Col} controlId="formGridParagraph">
                <Form.Label>
                  {t("submit-comment-paragraph")}{" "}
                  <CopyToClipboard text={this.state.paragraph}>
                    <FontAwesomeIcon icon="copy" />
                  </CopyToClipboard>
                </Form.Label>
                <Form.Control
                  type="text"
                  readonly
                  name="paragraph"
                  defaultValue={this.props.comment.paragraph}
                />
              </Form.Group>
            ) : (
              ""
            )}
            {this.state.document.initialValues.hasOwnProperty("chapter") ? (
              <Form.Group as={Col} controlId="formGridChapter">
                <Form.Label>
                  {t("submit-comment-chapter")}{" "}
                  <CopyToClipboard text={this.state.chapter}>
                    <FontAwesomeIcon icon="copy" />
                  </CopyToClipboard>
                </Form.Label>
                <Form.Control
                  type="number"
                  readonly
                  name="chapter"
                  value={this.props.comment.chapter}
                />
              </Form.Group>
            ) : (
              ""
            )}

            {this.state.document.initialValues.hasOwnProperty("diagram") ? (
              <Form.Group as={Col} controlId="formGridDiagram">
                <Form.Label>
                  {t("submit-comment-diagram")}{" "}
                  <CopyToClipboard text={this.state.diagram}>
                    <FontAwesomeIcon icon="copy" />
                  </CopyToClipboard>
                </Form.Label>
                <Form.Control
                  type="text"
                  readonly
                  name="diagram"
                  value={this.props.comment.diagram}
                />
              </Form.Group>
            ) : (
              ""
            )}
            {this.state.document.initialValues.hasOwnProperty("policy") ? (
              <Form.Group as={Col} controlId="formGridPolicy">
                <Form.Label>
                  {t("submit-comment-policy")}{" "}
                  <CopyToClipboard text={this.state.policy}>
                    <FontAwesomeIcon icon="copy" />
                  </CopyToClipboard>
                </Form.Label>
                <Form.Control
                  type="text"
                  readonly
                  name="policy"
                  defaultValue={this.props.comment.policy}
                />
              </Form.Group>
            ) : (
              ""
            )}
          </Form.Row>
        </Form>
        <CopyToClipboard text={"wget " + this.state.files.join(" ")}>
          <small>
            Quick download <FontAwesomeIcon icon="copy" />
          </small>
        </CopyToClipboard>
        <ul>
          {this.props.comment.attachments.map((attachment, idx) => {
            return (
              <li key={idx}>
                <small>
                  <a
                    href="#/"
                    onClick={() => this.downloadAttachment(attachment)}
                  >
                    {t("download-attachment")}
                  </a>
                  `
                </small>
              </li>
            );
          })}
        </ul>

      </Container>
    );
  }
}

export default withTranslation()(ViewComment);
