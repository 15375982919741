import React from "react";
import Comment from "./Comment";
import { Container, Row, Col } from "react-bootstrap";
import firebase from "../firebase";
import { withTranslation } from "react-i18next";

class MyComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: []
    };
  }

  componentDidMount() {
    const db = firebase.firestore();
    db.collection("comments")
      .where("uid", "==", firebase.auth().currentUser.uid)
      .onSnapshot(snapshot => {
        let comments = [];
        snapshot.forEach(comment => {
          comments.push(comment.data());
        });

        this.setState({
          comments
        });
      });
  }

  render() {
    return (
      <Container className="pb-5 mt-4">
        <Row className="justify-content-center">
          <Col className="col-lg-8 col-xs-1">
            <h1>{this.props.t("my-comments-title")}</h1>

            {this.state.comments.map((commentObj, idx) => {
              return (
                <React.Fragment key={idx}>
                  <Comment {...commentObj} /> <hr />
                </React.Fragment>
              );
            })}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(MyComments);
