import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import firebase from "./firebase";

//Styles and Bootstrap
import "./App.css";
import "./custom.scss";

//Translation
import "./i18n";
import { withTranslation } from "react-i18next";

//Compontents
import Navigation from "./components/Navigation";
import LoadingWidget from "./components/LoadingWidget";

//Routes
import Signin from "./components/Signin";
import Register from "./components/Register";
import SignInRedirect from "./components/SignInRedirect";
import MyComments from "./components/MyComments";
import LDPDocuments from "./components/LDPDocuments";
import SubmitComment from "./components/SubmitComment";
import SubmitCandidateSite from "./components/SubmitCandidateSite";
import ViewCandidateSites from "./components/ViewCandidateSites";
import LDPDocument from "./components/LDPDocument"
import Reconcile from "./components/Reconcile";

const excludeNavOnRoutes = ["/email-sign-in"];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      profile: null,
      requiresRegistration: false
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.lookupProfile(user);
      } else {
        this.setState({ loaded: true });
      }
    });
  }

  lookupProfile(user) {
    const db = firebase.firestore();
    db.collection("users")
      .doc(user.uid)
      .get()
      .then(profile => {
        if (profile.exists) {
          //User exists - we just need to navigate to home
          this.setState({
            profile: profile.data(),
            requiresRegistration: false,
            loaded: true
          });
        } else {
          //We need to get the users profile
          this.setState({
            requiresRegistration: true,
            loaded: true
          });
        }
      })
      .catch(error => {
        this.setState({ loaded: true });
      });
  }

  registrationComplete = () => {
    this.lookupProfile(firebase.auth().currentUser);
  };

  render() {
    const t = this.props.t;

    //Show the loading spinner when we don't know whether user is logged in or not, unless we're on the email signin.
    if (!this.state.loaded && window.location.pathname !== "/email-sign-in") {
      return <LoadingWidget message={t("loading")} />;
    }

    return (
      <Router>
        {this.state.requiresRegistration ? (
          <Redirect to={{ pathname: "/register" }} />
        ) : (
          ""
        )}

        {excludeNavOnRoutes.indexOf(window.location.pathname) < 0 && (
          <Navigation profile={this.state.profile} />
        )}

        <Switch>
          <Route exact path="/" component={LDPDocuments} />
          <Route path="/documents/:doc" component={LDPDocument} />
          <Route path="/submit-comment" component={SubmitComment} />
          <Route exact path="/sign-in" component={Signin} />
          <Route exact path="/view-candidate-sites" component={ViewCandidateSites} />

          <Route
            exact
            path="/register"
            render={props => {
              return (
                <Register complete={this.registrationComplete} {...props} />
              );
            }}
          />
          <Route exact path="/email-sign-in" component={SignInRedirect} />
          <Route
            path="/my-comments"
            render={props => {
              return <MyComments profile={this.state.profile} />;
            }}
          />
          <Route path="/comment" component={MyComments} />
          <Route
            path="/candidate-site-comment"
            component={CandidateSiteComment}
          />
          <Route
            path="/submit-candidate-site"
            component={SubmitCandidateSite}
          />
          <Route exact path="/reconcile" component={Reconcile} />
        </Switch>
      </Router>
    );
  }
}

export default withTranslation()(App);

function CandidateSiteComment() {
  return <h1>Candidate site comment</h1>;
}
