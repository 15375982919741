import React from "react";
import { Redirect } from "react-router-dom";
import { Container, Button, Form, Row, Col, Alert } from "react-bootstrap";
import firebase from "../firebase";
import { withTranslation, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Signin extends React.Component {
  constructor(props) {
    super(props);

    const rememberedEmail = localStorage.getItem("email", "");

    this.state = {
      email: rememberedEmail,
      emailSent: false,
      error: null,
      rememberMe: rememberedEmail === "" ? false : true
    };
  }

  handleChange = event => {
    this.setState({ email: event.target.value });
  };

  handleRememberMe = event => {
    if (!event.target.checked) {
      localStorage.setItem("email", "")
    }
    this.setState({ rememberMe: event.target.checked });
  };

  handleSubmit = event => {
    event.preventDefault();

    if (this.state.rememberMe) {
      localStorage.setItem("email", this.state.email);
    }
    this.sendEmail();
  };

  sendEmail = () => {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: window.location.origin + "/email-sign-in",
      // This must be true.
      handleCodeInApp: true
    };

    firebase
      .auth()
      .sendSignInLinkToEmail(this.state.email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", this.state.email);
        this.setState({ emailSent: true });
      })
      .catch(error => {
        // Some error occurred, you can inspect the code: error.code
        this.setState({ error });
      });
  };

  render() {
    const t = this.props.t;

    if (firebase.auth().currentUser) {
      return <Redirect to="/" />;
    }

    if (this.state.emailSent) {
      return <CheckEmail />;
    }

    return (
      <Container xs className="my-4">
        <Row className="justify-content-center">
          <Col className="col-md-6 col-xs-11">
            {this.state.error ? (
              <Alert variant="danger">{this.state.error.message}</Alert>
            ) : (
              ""
            )}
            <h1>{t("signin-title")}</h1>
            <p>{t("signin-explanation")}</p>
            <p>{t("signin-explanation-continued")}</p>
            <Form
              onSubmit={event => {
                this.handleSubmit(event);
              }}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  size="lg"
                  type="email"
                  required
                  value={this.state.email}
                  placeholder="Enter email"
                  onChange={this.handleChange}
                />

                <Form.Text className="text-muted">
                  {t("We'll never share your email with anyone else.")}
                </Form.Text>
                <Form.Check
                  id="rememberMe"
                  onChange={this.handleRememberMe}
                  name="rememberMe"
                  type="checkbox"
                  defaultChecked={this.state.rememberMe}
                  label={this.props.t("sign-in-remember-me")}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                {this.props.t("sign-in-button")}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(Signin);

function CheckEmail() {
  const { t } = useTranslation();

  return (
    <Container className="pb-5 mt-4">
      <Row className="justify-content-center">
        <Col className="col-md-6 col-xs-1">
          <h1>
            <FontAwesomeIcon icon="envelope-open" />{" "}
            {t("signin-check-your-email")}
          </h1>
          <p>{t("signin-check-your-email-explanation")}</p>
        </Col>
      </Row>
    </Container>
  );
}
