import React from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import firebase from "../firebase";

const Comment = props => {
  const { t } = useTranslation();

  const downloadAttachment = filename => {
    firebase
      .storage()
      .ref("attachments")
      .child(filename)
      .getDownloadURL()
      .then(url => window.location = url);
  };

  return (
    <Container>
      <Response response={props.response} />
      <p>{props.comment}</p>
      <Row>
        {props.hasOwnProperty("chapter") && props.chapter !== "" ? (
          <Col md={2}>
            <small>
              {t("chapter")}: {props.chapter}
            </small>
          </Col>
        ) : (
          ""
        )}
        {props.hasOwnProperty("paragraph") && props.paragraph !== "" ? (
          <Col md={2}>
            <small>
              {t("paragraph")}: {props.paragraph}
            </small>
          </Col>
        ) : (
          ""
        )}
        {props.hasOwnProperty("policy") && props.policy !== "" ? (
          <Col md={2}>
            <small>
              {t("policy")}: {props.policy}
            </small>
          </Col>
        ) : (
          ""
        )}
        {props.hasOwnProperty("diagram") && props.diagram !== "" ? (
          <Col md={2}>
            <small>
              {t("diagram")}: {props.diagram}
            </small>
          </Col>
        ) : (
          ""
        )}
      </Row>
      {props.hasOwnProperty("attachments") && props.attachments.length > 0 ? (
        <Row>
          <Col>
            <small>{t("attachments")}:</small>
            <ul>
              {props.attachments.map((attachment, idx) => {
                return (
                  <li key={idx}>
                    <small>
                      <a href="#/" onClick={()=> downloadAttachment(attachment)}>{t("download-attachment")}</a>`
                    </small>
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </Container>
  );
};

export default Comment;

const Response = props => {
  const { t } = useTranslation();

  let variant = "secondary";
  if (props.response) {
    switch (props.response) {
      case "objecting":
        variant = "danger";
        break;
      case "commenting":
        variant = "secondary";
        break;
      case "supporting":
        variant = "success";
        break;
      default:
    }
  }

  return <Badge variant={variant}>{t(props.response)}</Badge>;
};
