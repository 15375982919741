import React from "react";
import { Container, Button, Form, Row, Col, Alert } from "react-bootstrap";
import firebase from "../firebase";
import { withTranslation, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { string, bool, object } from "yup";
import { Formik } from "formik";
import i18n from "i18next";

const schema = object({
  title: string(),
  firstname: string().required(),
  lastname: string().required(),
  address: string(),
  postcode: string(),
  phone: string(),
  preferredContactMethod: string().required(),
  acceptTerms: bool().oneOf([true], i18n.t("register-must-accept-terms"))
});

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = { registered: false };
  }

  registerUser = (fields, actions) => {
    fields["email"] = firebase.auth().currentUser.email;

    const db = firebase.firestore();

    db.collection("users")
      .doc(firebase.auth().currentUser.uid)
      .set({ ...fields })
      .then(() => {
        firebase
          .auth()
          .currentUser.updateProfile({
            displayName: fields.firstname + " " + fields.lastname
          })
          .then(() => {
            this.setState({ registered: true });
            actions.setSubmitting(false);
          })
          .catch(error => {
            this.setState({ error });
            actions.setSubmitting(false);
          });
      })
      .catch(error => {
        console.error("Error writing document: ", error);
        this.setState({ error });
        actions.setSubmitting(false);
      });
  };

  completeRegistration = () => {
    this.props.history.push("/");
    this.props.complete();
  };

  render() {
    const t = this.props.t;

    if (this.state.registered) {
      return <RegisteredConfirmation complete={this.completeRegistration} />;
    }

    return (
      <Container className="pb-5 mt-4">
        <Row className="justify-content-center">
          <Col className="col-md-6 col-xs-1">
            {this.state.error ? (
              <Alert variant="danger">{this.state.error.message}</Alert>
            ) : (
              ""
            )}
            <h1>{t("register-heading")}</h1>
            <p>{t("register-explanation")}</p>
            <Formik
              validationSchema={schema}
              onSubmit={this.registerUser}
              initialValues={{
                preferredContactMethod: "Email",
                title: "",
                firstname: "",
                lastname: "",
                phone: "",
                postcode: "",
                address: "",
                acceptTerms: false
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting
              }) => (
                <Form className="mt-3" onSubmit={handleSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} md={2} controlId="formGridTitle">
                      <Form.Label>{t("register-title")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="title"
                        onChange={handleChange}
                        isInvalid={!!errors.title}
                        value={values.title}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridFirstName">
                      <Form.Label>{t("register-first-name")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="firstname"
                        onChange={handleChange}
                        isInvalid={!!errors.firstname}
                        value={values.firstname}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridLastName">
                      <Form.Label>{t("register-last-name")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="lastname"
                        onChange={handleChange}
                        isInvalid={!!errors.lastname}
                        value={values.lastname}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Group controlId="formGridAddress1">
                    <Form.Label>{t("register-address")}</Form.Label>
                    <Form.Control
                      placeholder=""
                      name="address"
                      onChange={handleChange}
                      isInvalid={!!errors.address}
                      value={values.address}
                    />
                  </Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} md={3} controlId="formGridPostCode">
                      <Form.Label>{t("register-postcode")}</Form.Label>
                      <Form.Control
                        placeholder=""
                        name="postcode"
                        onChange={handleChange}
                        isInvalid={!!errors.postcode}
                        value={values.postcode}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={4} controlId="formGridPhone">
                      <Form.Label>{t("register-phone")}</Form.Label>
                      <Form.Control
                        placeholder=""
                        name="phone"
                        onChange={handleChange}
                        isInvalid={!!errors.phone}
                        value={values.phone}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>
                        {t("register-preferred-contact-method")}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="preferredContactMethod"
                        onChange={handleChange}
                        isInvalid={!!errors.preferredContactMethod}
                        value={values.preferredContactMethod}
                      >
                        <option disabled>Choose one...</option>
                        <option>Email</option>
                        <option>Phone</option>
                        <option>Post</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>

                  <Form.Group id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label={t("register-accept-terms")}
                      name="acceptTerms"
                      onChange={handleChange}
                      isInvalid={!!errors.acceptTerms}
                      value={values.acceptTerms}
                      feedback={errors.acceptTerms}
                      id="validationAcceptTerms"
                    />
                    <small>{t("register-explanation-continued")}</small>
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("registered-continue-button")}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(Register);

const RegisteredConfirmation = props => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row className="justify-content-center">
        <Col className="col-md-6 col-xs-1">
          <h1>
            <FontAwesomeIcon icon="check-circle" />{" "}
            {t("registered-confirmation-title")}
          </h1>
          <p>{t("registered-confirmation-information")}</p>
          <Button onClick={props.complete}>
            {t("registered-continue-button")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
