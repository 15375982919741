import React from "react";
import ViewComment from "./ViewComment";
import User from "./User";
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Nav,
} from "react-bootstrap";
import firebase from "../firebase";
import { withTranslation } from "react-i18next";

class Reconcile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      index: 0,
      inputIndex: 1,
      loaded: false
    };
  }

  componentDidMount() {
    const db = firebase.firestore();
    db.collection("comments")
      //.where("uid", "==", "4UFy8XgX4MXJOdoqVl0oqECKGcN2")
      .onSnapshot(snapshot => {
        let comments = [];
        snapshot.forEach(comment => {
          comments.push({ ...comment.data(), cid: comment.id });
        });

        this.setState({
          comments,
          loaded: true
        });
      });
  }

  totalReconciled() {
    return this.state.comments.reduce((acc, item) => {
      return item.reconciled ? acc + 1 : acc;
    }, 0);
  }

  nextComment = () => {
    this.setState({
      index: this.state.index + 1,
      inputIndex: this.state.index + 2
    });
  };

  previousComment = () => {
    this.setState({
      index: this.state.index - 1,
      inputIndex: this.state.index
    });
  };

  updateIndex = evt => {
    const idx = parseInt(evt.target.value);
    if (!isNaN(idx)) {
      this.setState({ index: idx, inputIndex: evt.target.value });
    }
    this.setState({ inputIndex: evt.target.value });
  };

  reconcile = () => {
    const db = firebase.firestore();
    db.collection("comments")
      .doc(this.state.comments[this.state.index].cid)
      .update({ reconciled: true })
      .then(() => {
      });
  };

  unReconcile = () => {
    const db = firebase.firestore();
    db.collection("comments")
      .doc(this.state.comments[this.state.index].cid)
      .update({ reconciled: false })
      .then(() => {
      });
  };

  render() {
    if (!this.state.loaded) return "Loading";

    const comment = this.state.comments[this.state.index];

    let reconciledButton;

    if (comment.reconciled) {
    } else {
      reconciledButton = (
        <Button variant="primary" onClick={this.reconcile}>
          Reconcile
        </Button>
      );
    }

    if (this.state.comments.length > 0) {
      return (
        <Container className="pb-5 mt-4">
          <Row className="justify-content-center">
            <Col className="col-lg-8 col-xs-1">
              <h1>
                Reconcile{" "}
                <Badge variant="secondary">
                  {this.totalReconciled()}/{this.state.comments.length}
                </Badge>
              </h1>
              <Nav>
                <Nav.Item className="ml-auto" onClick={this.previousComment}>
                  <Nav.Link disabled={this.state.index === 0}>
                    Previous
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link>
                    <input
                      type="number"
                      style={{ width: "50px" }}
                      onChange={this.updateIndex}
                      value={this.state.inputIndex}
                    />
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="mr-auto">
                  <Nav.Link
                    disabled={
                      this.state.index + 1 === this.state.comments.length
                    }
                    onClick={this.nextComment}
                  >
                    Next
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {this.state.comments[this.state.index].reconciled ? (
                <Button onClick={this.unReconcile} variant="success">
                  Unreconcile
                </Button>
              ) : (
                ""
              )}

              {reconciledButton}

              <User uid={comment.uid} />
              <ViewComment comment={comment} />
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default withTranslation()(Reconcile);
