import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Button,
  Form,
  Row,
  Col,
  Alert,
  ProgressBar,
  Badge
} from "react-bootstrap";
import firebase from "../firebase";
import { withTranslation, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { string, bool, object, number } from "yup";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import i18n from "i18next";

import { Formik } from "formik";
const query = require("query-string");

const documentFields = {
  LDP2_PS: {
    type: "LDP2_PS",
    title: "Preferred Strategy",
    schema: object({
      response: string().oneOf(
        ["supporting", "objecting", "commenting"],
        i18n.t("submit-comment-must-give-response")
      ),
      comment: string().required(),
      paragraph: number(),
      chapter: number(),
      diagram: number(),
      policy: string(),
      wholeDocument: bool(),
      acceptTerms: bool().oneOf(
        [true],
        i18n.t("submit-comment-must-accept-terms")
      )
    }),
    initialValues: {
      response: "Choose one...",
      paragraph: "",
      chapter: "",
      diagram: "",
      policy: "",
      wholeDocument: false,
      acceptTerms: false,
      comment: ""
    }
  },
  LDP2_SA: {
    type: "LDP2_SA",
    title: "Sustainability Appraisal (SA)",
    schema: object({
      paragraph: number(),
      chapter: number(),
      diagram: number(),
      wholeDocument: bool(),
      acceptTerms: bool().oneOf(
        [true],
        i18n.t("submit-comment-must-accept-terms")
      ),
      comment: string().required()
    }),
    initialValues: {
      paragraph: "",
      chapter: "",
      diagramme: "",
      wholeDocument: false,
      acceptTerms: true,
      comment: ""
    }
  },
  LDP2_HRA: {
    type: "LDP2_HRA",
    title: "Habitat Regulations Assessment (HRA)",
    schema: object({
      comment: string().required(),
      paragraph: number(),
      chapter: number(),
      diagram: number(),
      acceptTerms: bool().required(),
      policy: string(),
      wholeDocument: bool
    }),
    initialValues: {
      paragraph: "",
      chapter: "",
      diagram: "",
      wholeDocument: false,
      acceptTerms: false,
      comment: ""
    }
  },
  LDP2_CS_1: {
    type: "LDP2_CS_1",
    title: "Candidate Site",
    schema: object({
      acceptTerms: bool().required(),
      comment: string().required()
    }),
    initialValues: {
      comment: "",
      acceptTerms: false
    }
  },
  LDP2_EVI: {
    type: "LDP2_EVI",
    title: "Evidence",
    schema: object({
      documentTitle: string().required(),
      acceptTerms: bool().required(),
      comment: string().required()
    }),
    initialValues: {
      documentTitle: "",
      comment: "",
      acceptTerms: false
    }
  }
};

class SubmitComment extends React.Component {
  constructor(props) {
    super(props);
    let document;
    let docid = "";
    let siteref = "";

    if (!this.props.hasOwnProperty("policy")) {
      //Should always have a policy ref
      document = documentFields[query.parse(window.location.search)["policy"]];

      if (query.parse(window.location.search)["document"] !== undefined) {
        docid = query.parse(window.location.search)["document"];
      }
      if (query.parse(window.location.search)["siteref"] !== undefined) {
        siteref = query.parse(window.location.search)["siteref"];
      }
    } else { //Path from submitting candidate site.
      document = documentFields[this.props.policy];
    }

    this.state = {
      submitted: false,
      attachments: [],
      filenames: [],
      document,
      docid,
      siteref
    };
  }

  handleUploadStart = file => {
    this.setState(state => {
      const filenames = state.filenames.concat(file.name);
      return {
        filenames,
        isUploading: true,
        progress: 0
      };
    });
  };

  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    this.setState(state => {
      const attachments = state.attachments.concat(filename);
      return {
        attachments,
        isUploading: false,
        progress: 100
      };
    });
  };

  submitComment = (fields, actions) => {
    //Add the uid in to the record for a reference.
    fields["uid"] = firebase.auth().currentUser.uid;
    fields["attachments"] = this.state.attachments;
    fields["type"] = this.state.document.type;
    if (this.state.docid !== "" ) {
      fields["docid"] = this.state.docid;
    }
    if (this.state.siteref !== "" ) {
      fields["siteref"] = this.state.siteref;
    }
    //If we've been passed any properties we should merge these in.
    if (
      this.props.location.hasOwnProperty("state") &&
      this.props.location.state &&
      this.props.location.state.hasOwnProperty("geoJSON")
    ) {
      fields["geoJSON"] = JSON.stringify(this.props.location.state["geoJSON"]);
    } else if (
      this.props.location.hasOwnProperty("state") &&
      this.props.location.state &&
      this.props.location.state.hasOwnProperty("candidateSite")
    ) {
      fields["candidateSiteGeoJSON"] = this.props.location.state.candidateSite;
    }
    const db = firebase.firestore();

    db.collection("comments")
      .doc()
      .set({ ...fields })
      .then(() => {
        this.setState({ submitted: true });
        actions.setSubmitting(false);
      })
      .catch(error => {
        console.error("Error writing document: ", error);
        this.setState({ error });
        actions.setSubmitting(false);
      });
  };

  removeAttachments = () => {
    this.setState({ attachments: [], filenames: [] });
  };

  completeSubmission = () => {
    this.props.history.push("/");
  };

  render() {
    const t = this.props.t;

    if (!this.state.document) {
      return <Redirect to="/" />;
    }

    if (this.state.submitted) {
      return <SubmissionConfirmation complete={this.completeSubmission} />;
    }

    let submitText;
    if (
      this.props.hasOwnProperty("location") &&
      this.props.location.hasOwnProperty("state") &&
      this.props.location.state &&
      (this.props.location.state.hasOwnProperty("geoJSON") ||
        this.props.location.state.hasOwnProperty("candidateSite"))
    ) {
      submitText = this.props.t("submit-comment-and-site-submit-button");
    } else {
      submitText = this.props.t("submit-comment-submit-button");
    }

    return (
      <Container className="pb-5 mt-4">
        <Row className="justify-content-center">
          <Col className="col-lg-8 col-xs-1">
            {this.state.error ? (
              <Alert variant="danger">{this.state.error.message}</Alert>
            ) : (
              ""
            )}
            <Badge variant="secondary">{this.state.document.title}</Badge>
            <h1>{t("submit-comment-heading")}</h1>
            <p>{t("submit-comment-explanation")}</p>
            <Formik
              validationSchema={this.state.document.schema}
              onSubmit={this.submitComment}
              initialValues={this.state.document.initialValues}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting
              }) => (
                <Form className="mt-3" onSubmit={handleSubmit}>
                  {this.state.document.initialValues.hasOwnProperty(
                    "response"
                  ) ? (
                    <Form.Row>
                      <Form.Group as={Col} md={4} controlId="formGridResponse">
                        <Form.Label>
                          {t("submit-comment-response-field")}
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="response"
                          onChange={handleChange}
                          isInvalid={!!errors.response}
                          value={values.response}
                          feedback={errors.response}
                        >
                          <option value="not-chosen">
                            {t("submit-comment-choose-one")}
                          </option>
                          <option value="objecting">
                            {t("submit-comment-objecting")}
                          </option>
                          <option value="supporting">
                            {t("submit-comment-supporting")}
                          </option>
                          <option value="commenting">
                            {t("submit-comment-commenting")}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                  ) : (
                    ""
                  )}

                  {this.state.document.initialValues.hasOwnProperty(
                    "documentTitle"
                  ) ? (
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridDocumentTitle">
                        <Form.Label>
                          {t("submit-comment-document-title")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="documentTitle"
                          onChange={handleChange}
                          isInvalid={!!errors.documentTitle}
                          value={values.documentTitle}
                        />
                      </Form.Group>
                    </Form.Row>
                  ) : (
                    ""
                  )}

                  <Form.Group controlId="formGridComment">
                    <Form.Label>
                      {t("submit-comment-comment-field-placeholder")}
                    </Form.Label>
                    <Form.Control
                      placeholder={t("What would you like to comment on?")}
                      name="comment"
                      onChange={handleChange}
                      isInvalid={!!errors.comment}
                      value={values.comment}
                      as="textarea"
                      rows="5"
                    />
                  </Form.Group>

                  {this.state.document.initialValues.hasOwnProperty(
                    "wholeDocument"
                  ) ? (
                    <Form.Group id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label={t("submit-comment-whole-document")}
                        name="wholeDocument"
                        onChange={handleChange}
                        isInvalid={!!errors.wholeDocument}
                        value={values.wholeDocument}
                        feedback={errors.wholeDocument}
                        id="wholeDocument"
                      />
                    </Form.Group>
                  ) : (
                    ""
                  )}
                  <Form.Row>
                    {this.state.document.initialValues.hasOwnProperty(
                      "paragraph"
                    ) ? (
                      <Form.Group as={Col} controlId="formGridParagraph">
                        <Form.Label>{t("submit-comment-paragraph")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="2.4"
                          name="paragraph"
                          onChange={handleChange}
                          isInvalid={!!errors.paragraph}
                          value={values.paragraph}
                        />
                      </Form.Group>
                    ) : (
                      ""
                    )}
                    {this.state.document.initialValues.hasOwnProperty(
                      "chapter"
                    ) ? (
                      <Form.Group as={Col} controlId="formGridChapter">
                        <Form.Label>{t("submit-comment-chapter")}</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="3"
                          name="chapter"
                          onChange={handleChange}
                          isInvalid={!!errors.chapter}
                          value={values.chapter}
                        />
                      </Form.Group>
                    ) : (
                      ""
                    )}

                    {this.state.document.initialValues.hasOwnProperty(
                      "diagram"
                    ) ? (
                      <Form.Group as={Col} controlId="formGridDiagram">
                        <Form.Label>{t("submit-comment-diagram")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="4.5.3"
                          name="diagram"
                          onChange={handleChange}
                          isInvalid={!!errors.diagram}
                          value={values.diagram}
                        />
                      </Form.Group>
                    ) : (
                      ""
                    )}
                    {this.state.document.initialValues.hasOwnProperty(
                      "policy"
                    ) ? (
                      <Form.Group as={Col} controlId="formGridPolicy">
                        <Form.Label>{t("submit-comment-policy")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="4"
                          name="policy"
                          onChange={handleChange}
                          isInvalid={!!errors.policy}
                          value={values.policy}
                        />
                      </Form.Group>
                    ) : (
                      ""
                    )}
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col}>
                      <CustomUploadButton
                        name="attachments"
                        randomizeFilename
                        multiple
                        storageRef={firebase.storage().ref("attachments")}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                        style={{
                          backgroundColor: "grey",
                          color: "white",
                          padding: 6,
                          borderRadius: 4
                        }}
                      >
                        {t("submit-comment-attachments")}
                      </CustomUploadButton>

                      <ul>
                        {this.state.filenames.map(file => {
                          return <li key={file}>{file}</li>;
                        })}
                        {this.state.isUploading && (
                          <ProgressBar
                            variant="success"
                            now={this.state.progress}
                            label={`${this.state.progress}%`}
                          />
                        )}
                      </ul>
                      {this.state.attachments.length > 0 ? (
                        <Button onClick={this.removeAttachments}>
                          {t("Remove Attachments")}
                        </Button>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Form.Row>

                  <Form.Group id="formGridCheckbox">
                    <Form.Check
                      required
                      type="checkbox"
                      label={t("submit-comment-terms")}
                      name="acceptTerms"
                      onChange={handleChange}
                      isInvalid={!!errors.acceptTerms}
                      value={values.acceptTerms}
                      feedback={errors.acceptTerms}
                      id="acceptTerms"
                    />
                    <small>{t("submit-comment-explanation-continued")}</small>
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {submitText}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(SubmitComment);

const SubmissionConfirmation = props => {
  const { t } = useTranslation();
  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col className="col-6">
          <h1>
            <FontAwesomeIcon icon="check-circle" />{" "}
            {t("submit-comment-confirmation-title")}
          </h1>
          <p>{t("submit-comment-confirmation-information")}</p>
          <Button onClick={props.complete}>
            {t("submit-comment-continue-button")}
          </Button>
          {/*<Button varient="secondary" onClick={props.complete}>
            {t("submit-comment-submit-another-button")}
            </Button>*/}
        </Col>
      </Row>
    </Container>
  );
};
