import React from "react";
import { Container, Row, Col, Badge, FormControl } from "react-bootstrap";
import firebase from "../firebase";
import { withTranslation, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const DOCUMENT_LIST_ENDPOINT = "https://api.beacons-npa.gov.uk/ldp/list";

class LDPDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentId: this.props.match.params.doc,
      documentTitle: this.props.t(this.props.match.params.doc),
      documents: [],
      filter: ""
    };
  }

  componentDidMount() {
    fetch(DOCUMENT_LIST_ENDPOINT)
      .then(response => response.json())
      .then(data => {
        if (
          typeof data[this.state.documentId] === "object" &&
          !Array.isArray(data[this.state.documentId])
        ) {
          this.setState({
            documents: Array(data[this.state.documentId])
          });
        } else if (
          typeof data[this.state.documentId] === "object" &&
          Array.isArray(data[this.state.documentId])
        ) {
          this.setState({ documents: data[this.state.documentId] });
        }
      });
  }

  handleChange = event => {
    this.setState({ filter: event.target.value });
  };

  showDocument = doc => {
    if (this.state.filter !== "") {
      if (doc.desc.toLowerCase().includes(this.state.filter.toLowerCase())) {
        return true;
      }
      if (
        doc.type
          .toString()
          .toLowerCase()
          .includes(this.state.filter.toLowerCase())
      ) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <Container className="mt-3">
        <h1>{this.state.documentTitle}</h1>
        <Link to={"/"}>&lt; {this.props.t("ldp-document-back-to-docs")}</Link>
        <hr />
        <Row className="my-2 justify-content-end">
          <Col className="col-md-4 ">
            <FormControl
              type="text"
              placeholder={this.props.t("ldp-document-search-document")}
              className="mr-sm-2"
              onChange={this.handleChange}
              value={this.state.filter}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Document</h4>
          </Col>
          <Col>
            <h4>Type</h4>
          </Col>
          <Col />
        </Row>

        {this.state.documents.map((doc, idx) => {
          if (this.showDocument(doc)) {
            return <Document key={idx} policy={this.state.documentId} {...doc} />;
          } else {
            return "";
          }
        })}
      </Container>
    );
  }
}
export default withTranslation()(LDPDocument);

const CommentLink = props => {
  const { t } = useTranslation();

  if (!firebase.auth().currentUser) {
    return (
      <Badge variant="warning">{t("ldp-document-comments-must-sign-in")}</Badge>
    );
  }

  return <Link to={"/submit-comment?policy="+ props.policy +"&document="+props.document}>{t("ldp-documents-submit-comment")}</Link>;
};

const Document = props => {
  return (
    <Row>
      <Col>
        <h4>
          <FontAwesomeIcon icon="file-alt" color="#ccc" />{" "}
          <a
            href={
              "https://api.beacons-npa.gov.uk/ldp/download?doc=" + props.docid
            }
          >
            {props.desc}
          </a>
        </h4>
      </Col>
      <Col>{props.type}</Col>
      <Col>{props.allow_comment !== 3 ? <CommentLink policy={props.policy} document={props.docid} /> : <Badge variant="secondary">Comments not open</Badge>}</Col>
    </Row>
  );
};
